import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import logo from '../Components/Images/logo.png'

function Footer() {
  return (
    <AppBar position="static" color="primary" sx={{ marginTop: '50px', backgroundColor:'white' }}>
      <Toolbar>
        <Typography variant="body1" color="black" sx={{flexGrow: 1}}>
          © 2024 Tyler Goodall
        </Typography>
        <img src={logo} alt='logo' style={{width: '120px', height: '120px'}}/>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;