import React from 'react';
import { Container, Typography, Grid, Card, CardMedia, CardContent } from '@mui/material';
import { Chip, Box } from '@mui/material';



const About = () => {

  function getRandomMargin() {
    return Math.floor(Math.random() * 50);
  }
  
  const languages = ['HTML', 'CSS', 'Sass', 'JavaScript','Java','PHP'];
  const skills = ['Web & Mobile Applications', 'Social Media Content', 'Logos'];


  return (
    <Container maxWidth="full" sx={{height:'100%', backgroundColor:'#283663', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center'}}>
    <Typography data-aos='fade-in' variant="h1" component="h1" gutterBottom sx={{fontSize: { xs: '20px', sm: '45px', md: '60px' }, whiteSpace:'nowrap', fontWeight:'600', color:'#2aa2de', padding:'20px', mt:'100px'}}>
          Hi I'm Tyler. Nice to meet you.
    </Typography>
    <Typography variant="h5" component="h2" gutterBottom sx={{fontSize: { xs: '15px', sm: '18px', md: '20px' }, fontWeight:'500', letterSpacing:'2px', color:'whitesmoke', mb:'40px', textAlign:'center', width:'70%'}}>
    Since graduating recently from Indiana University, I've embarked on my career with a blend of enthusiasm and fresh perspective. My name is Tyler Goodall, and I'm beginning to make my mark in the development world. Though new to the professional scene, I've engaged in projects that have allowed me to collaborate with innovative teams and apply my skills in creating digital solutions for businesses and consumers alike. I am quietly confident, driven by a natural curiosity, and continuously focused on honing my abilities to contribute meaningfully to the field.
   </Typography>

   <Grid container spacing={2} sx={{ display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
    <Grid data-aos='flip-right' item xs={12} sm={6} style={{ backgroundColor:'white', borderRadius:'16px', border:'2px solid #2aa2de', margin: '1em', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', textAlign:'center'}}>
  <Typography variant="h4" component="h2" gutterBottom sx={{fontWeight:'600', color:'#2aa2de', fontSize: { xs: '20px', sm: '25px', md: '30px' }}}>
     Design
    </Typography>
    <Typography variant="h5" component="h2" gutterBottom sx={{padding:'10px', color:'black',fontSize:"15px", fontWeight:'500', letterSpacing:'2px', textAlign:'center', width:'80%', margin: '0 auto'}}>
        I believe in creaftng clean content structures matched with seasmless functionality.
   </Typography>
    <Typography variant="h6" component="h2" gutterBottom sx={{color:'#2aa2de', fontWeight:'600', fontSize:'20px'}}>
     Things I enjoy to design:
    </Typography>
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
  {skills.map((skill) => (
    <Chip key={skill} label={skill} variant="outlined" sx={{ m: 1, borderRadius: 50, padding: 2 }} />
  ))}
</Box>
    <Typography variant="h6" component="h2" gutterBottom sx={{color:'#2aa2de', fontWeight:'600', fontSize:'20px'}}>
     Design Tools:
    </Typography>
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
  <Chip label="Adobe XD" variant="outlined" />
  <Chip label="Adobe Illustrator" variant="outlined" />
  <Chip label="Adobe Photoshop" variant="outlined" />
  <Chip label="Figma" variant="outlined" />
  <Chip label="Pen and Paper" variant="outlined" />
</Box>
  </Grid>
  <Grid data-aos='flip-left' item xs={12} sm={6} style={{ padding: '20px', backgroundColor:'white', borderRadius:'16px', border:'2px solid #2aa2de', margin: '1em', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', textAlign:'center'}}>
    <Typography variant="h4" component="h2" gutterBottom sx={{fontWeight:'600', color:'#2aa2de', fontSize: { xs: '20px', sm: '25px', md: '30px' }}}>
     Development
    </Typography>
    <Typography variant="h5" component="h2" gutterBottom sx={{padding:'10px', color:'black',fontSize:"15px", fontWeight:'500', letterSpacing:'2px', textAlign:'center', width:'80%', margin: '0 auto'}}>
        I love the proccess when it comes to development. Taking sometthing from absolutely nothing all the way to the finished product given to the client.
   </Typography>
    <Typography variant="h6" component="h2" gutterBottom sx={{color:'#2aa2de', fontWeight:'600', fontSize:'20px'}}>
        Languages:
    </Typography>
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
  {languages.map((language) => (
    <Chip key={language} label={language} variant="outlined" sx={{m: 1, borderRadius: 50, padding: 2 }} />
  ))}
</Box>
    <Typography variant="h6" component="h2" gutterBottom sx={{color:'#2aa2de', fontWeight:'600', fontSize:'20px'}}>
        Dev Tools: 
    </Typography>

<Box sx={{ '& > :not(style)': { m: 1 } }}>
  <Chip label="VSCode" variant="outlined" />
  <Chip label="GitHub" variant="outlined" />
  <Chip label="Slack" variant="outlined" />
  <Chip label="MySQL" variant="outlined" />
  <Chip label="React" variant="outlined" />
  <Chip label="Node.js" variant="outlined" />
  <Chip label="Express" variant="outlined" />
  <Chip label="Firebase" variant="outlined" />
</Box>
</Grid>
</Grid>
    </Container>
  );
};

export default About;