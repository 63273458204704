import React from 'react';
import { Container, Typography } from '@mui/material';
import hero from '../Components/Images/Asset 2.png';
import bottom from '../Components/Images/Hero-bottom.png';
import '../Components/Styles/Projects.css'

function Hero() {
  return (
    <Container maxWidth="md" sx={{position:'relative',  height: { xs: '80vh', sm: '150vh', md: '137vh' }, width:'100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt:'100px'}}>
      <Typography data-aos='zoom-in' variant="h1" component="h1" gutterBottom sx={{fontSize: { xs: '20px', sm: '45px', md: '60px' }, whiteSpace:'nowrap', fontWeight:'600', color:'#0a0a0a'}}>
        Designer, Developer, and Creator
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom sx={{fontSize: { xs: '15px', sm: '18px', md: '20px' }, fontWeight:'500', letterSpacing:'2px', mb:'50px', textAlign:'center'}}>
        I craft sleek and simple designs, fueled by pride and passion for my work.
      </Typography>
      <img src={hero} data-aos="fade-up" alt='hero' className='hero-top' style={{height: '250px', width:'250px', padding:'10px', filter: 'grayscale(27%)'}}/>
      <img src={bottom} className='hero-bottom' alt='hero-bottom' style={{position:'absolute', top: '53.5%', width: '80%', padding:'20px', display:{sm:'none'} }}/>
    </Container>
  )
}

export default Hero;