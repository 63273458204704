import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import logo from './Images/logo.png';
import logoAlt from '../Components/Images/logo-alt.png';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    const [logoSrc, setLogoSrc] = useState(logo);

    const scrollToProjects = () => {
      const projectsElement = document.getElementById('projects');
      projectsElement.scrollIntoView({ behavior: 'smooth' });
    };

  return (
    <AppBar position="static" sx={{backgroundColor: 'white', color: '#000', boxShadow:'none' }}>
      <Toolbar>
      <Link to='/' >
      <img data-aos='fade-right'
      src={logoSrc}
      alt='logo'
      style={{width: '120px', height: '120px', cursor: 'pointer'}}
      onMouseEnter={() => setLogoSrc(logoAlt)}
      onMouseLeave={() => setLogoSrc(logo)}
    />        
      </Link>
    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#000' }}>
        </Typography>
        {/* <Button color="inherit">Home</Button> */}
        {/* <Button color="inherit">About</Button> */}
        <Button onClick={scrollToProjects} color="inherit" sx={{mr:'10px'}}      
        onMouseEnter={(event) => {
            event.target.style.color = '#2aa2de';
            event.target.style.backgroundColor = 'white'
          }}
          onMouseLeave={(event) => {
            event.target.style.backgroundColor = '';
            event.target.style.color = 'black';
            event.target.style.border = 'none';
          }}
          >Projects</Button>
        <Button color="inherit"  sx={{
  '&:hover': {
    backgroundColor: 'transparent',
  }}}>
          <a href='/contact' style={{border:'1px solid #2aa2de', color:'#2aa2de', borderRadius:'35px', padding:'5px', textDecoration:'none'}}
          onMouseEnter={(event) => {
           event.target.style.backgroundColor = '#2aa2de';
           event.target.style.color = 'white';
         }}
         onMouseLeave={(event) => {
           event.target.style.backgroundColor = 'transparent';
           event.target.style.color = '#2aa2de';
           event.target.style.border = '1.5px solid #2aa2de'
         }}>Lets Work</a></Button>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
