import React from 'react';
import { BrowserRouter as Router, Switch, Routes, Route  } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Home from './Components/Pages/Home';
import Navbar from './Components/Navbar';
import Contact from './Components/Pages/Contact';
// import About from './components/About';
import Projects from './Components/Projects';
import Form from './Components/Form';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#283663',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#red',
    },
    background: {
      default: '#fff',
    },
  },
});

AOS.init({
  duration: 1000, 
  delay: 0, 
  offset: 120, 
  once: false, 
  mirror: false, 
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="#projects" element={<Projects />} />

        {/* other routes... */}
      </Routes>
    </Router>

    </ThemeProvider>
  );
}

export default App;