import React from 'react';
import { useState } from 'react';
import { CardActionArea, Box, Grid, Card, Container, CardContent, CardMedia, Typography } from '@mui/material';
import bottom from '../Components/Images/lb_computer.jpeg';
import '../Components/Styles/Projects.css';
import TRG from '../Components/Images/TRG.png';
import PM from '../Components/Images/PM.png';
import P from '../Components/Images/Precision.png';
import Link from 'react-router-dom';

function Projects() {
  const [hoveredCard, setHoveredCard] = useState(null);

    return (
      <Container id='projects' data-aos='fade-up' maxWidth="full" sx={{height:'130vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center', mt:'350px'}}>
            <img className='projects-top' src={bottom} alt='bottom' style={{height:'60%', filter: 'grayscale(20%)', marginBottom:'100px' }}/>

            <Typography variant="h1" component="h1" gutterBottom sx={{fontSize: { xs: '20px', sm: '45px', md: '60px' }, whiteSpace:'nowrap', fontWeight:'600', color:'#283663'}}>
              My Recent Work
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom sx={{fontSize: { xs: '15px', sm: '18px', md: '20px' }, fontWeight:'500', letterSpacing:'2px', color:'#0a0a0a', mb:'40px', width:'70%', textAlign:'center'}}>
        Here's a selection of my previous projects. Interested in learning more? Reach out!  </Typography>
        <Grid container spacing={2} sx={{display:'flex', justifyContent:'space-evenly', alignItems:'center', flexWrap:'wrap'}}>
        <Grid item xs={12} sm={6} md={4} lg={12} sx={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', flexWrap:'wrap'}}>
                <Card sx={{ maxWidth: '320px', height: '320px', padding: '20px', borderRadius: '16px', border: '2px solid #0a0a0a', mb: '10px' }}>
                  <CardActionArea
                    onMouseEnter={() => setHoveredCard('Project1')}
                    onMouseLeave={() => setHoveredCard(null)}
                    sx={{ '&:hover': { backgroundColor: 'white' } }} 
                  >
                    <Box sx={{ height: '140px', width:'270px', backgroundImage: `url(${P})`, backgroundSize: 'cover', borderRadius: '16px', '&:hover': { backgroundColor: 'white'} }} />
                    <a href='https://precisionrevamp.vercel.app/' style={{textDecoration:'none', color:'black'}}>
                    <CardContent sx={{ width: '300px' }}> 
                      <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { xs: '15px', sm: '18px', md: '20px' } }}>
                        Precision Paint
                      </Typography>
                      {/* {hoveredCard === 'Project1' && ( */}
                          <>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1, '&:hover': { backgroundColor: '#2aa2de'} }}>
                            <Typography variant="body2" color="text.secondary">
                              HTML
                            </Typography>
                          </Box>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1, '&:hover': { backgroundColor: '#2aa2de'} }}>
                            <Typography variant="body2" color="text.secondary">
                              CSS
                            </Typography>
                          </Box>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1, '&:hover': { backgroundColor: '#2aa2de'} }}>
                            <Typography variant="body2" color="text.secondary">
                              JavaScript
                            </Typography>
                          </Box>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1, '&:hover': { backgroundColor: '#2aa2de'} }}>
                            <Typography variant="body2" color="text.secondary">
                              React
                            </Typography>
                          </Box>
                          </>
                      {/* )} */}
                    </CardContent>
                    </a>
                  </CardActionArea>
                </Card>

                <Card sx={{ maxWidth: '320px', height: '320px', padding: '20px', borderRadius: '16px', border: '2px solid #0a0a0a', mb: '10px' }}>
                  <CardActionArea
                    onMouseEnter={() => setHoveredCard('Project2')}
                    onMouseLeave={() => setHoveredCard(null)}
                    sx={{ '&:hover': { backgroundColor: 'transparent' } }}  // Override hover styles
                  >
                    <Box sx={{ height: '140px', width:'270px', backgroundImage: `url(${TRG})`, backgroundSize: 'cover', borderRadius: '16px' }} />
                    <a href='https://trgdigitalsolutions.com/' style={{textDecoration:'none', color:'black'}}>
                    <CardContent sx={{ width: '300px' }}> 
                      <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { xs: '15px', sm: '18px', md: '20px' } }}>
                        TRG Digital Solutions
                      </Typography>
                      {/* {hoveredCard === 'Project2' && ( */}
                            <>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1, '&:hover': { backgroundColor: '#2aa2de'} }}>
                            <Typography variant="body2" color="text.secondary">
                              HTML
                            </Typography>
                          </Box>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1, '&:hover': { backgroundColor: '#2aa2de'} }}>
                            <Typography variant="body2" color="text.secondary">
                              CSS
                            </Typography>
                          </Box>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1, '&:hover': { backgroundColor: '#2aa2de'} }}>
                            <Typography variant="body2" color="text.secondary">
                              JavaScript
                            </Typography>
                          </Box>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1 , '&:hover': { backgroundColor: '#2aa2de'}}}>
                            <Typography variant="body2" color="text.secondary">
                              React
                            </Typography>
                          </Box>
                            </>
                      {/* )} */}
                    </CardContent>\
                    </a>
                  </CardActionArea>
                </Card>

                <Card sx={{ maxWidth: '320px', height: '320px', padding: '20px', borderRadius: '16px', border: '2px solid #0a0a0a' }}>
                  <CardActionArea
                    onMouseEnter={() => setHoveredCard('Project3')}
                    onMouseLeave={() => setHoveredCard(null)}
                    sx={{ '&:hover': { backgroundColor: 'transparent' } }}  // Override hover styles
                  >
                    <Box sx={{ height: '140px', width:'270px', backgroundImage: `url(${PM})`, backgroundSize: 'cover', borderRadius: '16px' }} />
                    <CardContent sx={{ width: '300px' }}>  
                      <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { xs: '15px', sm: '18px', md: '20px' } }}>
                        Pocket Methods
                      </Typography>
                      {/* {hoveredCard === 'Project3' && ( */}
                        <>
                       <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1, '&:hover': { backgroundColor: '#2aa2de'} }}>
                            <Typography variant="body2" color="text.secondary">
                              HTML
                            </Typography>
                          </Box>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1, '&:hover': { backgroundColor: '#2aa2de'} }}>
                            <Typography variant="body2" color="text.secondary">
                              CSS
                            </Typography>
                          </Box>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1, '&:hover': { backgroundColor: '#2aa2de'} }}>
                            <Typography variant="body2" color="text.secondary">
                              JavaScript
                            </Typography>
                          </Box>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1, '&:hover': { backgroundColor: '#2aa2de'}}}>
                            <Typography variant="body2" color="text.secondary" >
                              React
                            </Typography>
                          </Box>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1 , '&:hover': { backgroundColor: '#2aa2de'}} }>
                            <Typography variant="body2" color="text.secondary">
                              Node.js
                            </Typography>
                          </Box>
                          <Box sx={{ p: 1, border: '1px solid #000', borderRadius: '16px', display: 'inline-block', mr: 1, mb: 1, '&:hover': { backgroundColor: '#2aa2de' }}}>
                            <Typography variant="body2" color="text.secondary">
                              Express
                            </Typography>
                          </Box>
                      </>

                      {/* )} */}
                    </CardContent>
                  </CardActionArea>
                </Card>
            </Grid>
        </Grid>
        </Container>
    );
}

export default Projects;
