import React from 'react';
import Form from '../Form';

function Contact() {
    return (
        <div>
            <Form />
        </div>
    );
}

export default Contact;