import { Container, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import collab from './Images/collab.png';
import { Link } from 'react-router-dom';

function Collab() {
    return (
        <div data-aos='fade-up' style={{backgroundImage: `url(${collab})`, backgroundSize: 'cover', height:{sm:'45vh', md:'60vh'}, borderRadius:'16px', width:'95%', margin: '0 auto', boxShadow:'#000'}}>
            <Container maxWidth="md" sx={{}}>
                <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2} sx={{ mt:{xs:'100px', sm:'50px'}  }}>
                    <Grid item xs={12}>
                        <Typography variant="h1" component="h1" gutterBottom sx={{ fontSize: { xs: '15px', sm: '20px' }, whiteSpace: 'nowrap', fontWeight: '600', color: 'white', margin:'0 auto' }}>
                            Interested in collaborating with me?
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{ fontSize: { xs: '12px', sm: '15px' }, fontWeight: '500', letterSpacing: '2px', color: 'whitesmoke', mb: '3   0px', textAlign: 'center' }}>
                            I’m always open to discussing product design work or partnership opportunities.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                   <Link to={'/contact'}><Button sx={{borderRadius:'16px', backgroundColor:'transparent', border:'2px solid #2aa2de', mb:'10px'}}variant="contained" color="primary">Start a conversation</Button></Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Collab;

