import React from 'react';
import '../../App.css';
import Hero from '../Hero';
import Projects from '../Projects';
import About from '../About';
import Collab from '../Collab';
import Footer from '../Footer';
import Choose from '../Choose';

function Home() {
  return (
    <div>
        <Hero />
        <About />
        <Projects />
        <Choose />
        <Collab />
        {/* <Form /> */}
        <Footer />
    </div>
  );
}

export default Home;
