import React from 'react';
import { Grid, Typography } from '@mui/material';
import computer from '../Components/Images/lb_computer.jpeg.png';
import '../Components/Styles/Projects.css';


function Choose() {
  return (
    <div data-aos='fade-right'>
            <Grid container sx={{ justifyContent:'space-evenly', alignItems:'center', mt: '450px', textAlign:'center'}}>
          <Grid item xs={12} sm={10}>
        <Typography variant="h5" component="h2" gutterBottom sx={{ width:'100%', fontSize: { xs: '15px', sm: '20px' }, fontWeight: '500', letterSpacing: '2px', color: 'black', textAlign: 'center', lineHeight:'26px', mt:'-120px', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', padding:'20px'}}>
          <img src={computer} className='computer' alt='computer' style={{display:'flex', width:'auto', filter: 'grayscale(20%)', marginBottom:'-70px'}}/>
          Choosing the right web design and development expert is critical for bringing your vision to life, ensuring your project stands out not just in aesthetics but in functionality as well. My blend of creativity and technical acumen significantly influences the success of your online presence. I am dedicated to innovating and applying meticulous attention to each project, establishing a strong foundation for your continued success in the digital landscape.        </Typography>   
      </Grid>
      </Grid>
    </div>
  );
}

export default Choose;
