import React from 'react';
import { Button, TextField, FormControl, Typography, Grid } from '@mui/material';
import computer from '../Components/Images/lb_computer.jpeg.png';

const Form = () => {

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container spacing={2} sx={{ justifyContent:'space-evenly', alignItems:'center', mt: '200px', textAlign:'center'}}>
      <Grid item xs={12} sm={6}>
        <FormControl component="form" onSubmit={handleSubmit} sx={{height:'70%', width:'75%', border: '1px solid #283663', p:'50px', borderRadius:'16px'}}>
          <Typography variant="h3" component="h2" sx={{color:'#283663'}}>Lets Work</Typography>
          <TextField label="Name" variant="outlined"  margin="normal" fullWidth />
          <TextField label="Email" variant="outlined" margin="normal" fullWidth />
          <TextField label="Message" variant="outlined" margin="normal" fullWidth multiline rows={4} />
          <TextField label="Budget" variant="outlined" margin="normal" fullWidth sx={{mb:"15px"}} />
          <Button type="submit" variant="contained" sx={{height:'50px', width:'70%', borderRadius:'25px', margin:'0 auto', padding:'10px', backgroundColor:'transparent', color:'#283663', 
          fontSize:'15px'}}
            onMouseEnter={(event) => {
              event.target.style.backgroundColor = '#283663';
              event.target.style.color = 'white';
            }}
            onMouseLeave={(event) => {
              event.target.style.backgroundColor = '';
              event.target.style.color = '#283663';
              event.target.style.border = '1.5px solid #283663'
            }}
          >
            Submit
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h5" component="h2" gutterBottom sx={{ width:'100%', fontSize: { xs: '15px', sm: '20px' }, fontWeight: '500', letterSpacing: '2px', color: 'black', textAlign: 'center', lineHeight:'26px', mt:'-120px', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', padding:'80px'}}>
          <img src={computer} alt='computer' style={{display:'flex', height:'350px', width:'auto', filter: 'grayscale(20%)', marginBottom:'-70px'}}/>
          Choosing the right web design and development team is crucial for turning your vision into reality, ensuring your project is not just visually appealing but also functionally robust. A skilled team brings a blend of creativity and technical expertise that can significantly impact the success of your online presence. I take pride in the dedication and innovation I bring to each project, setting the foundations for my continued success in the digital landscape.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Form;